import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import apiService from '@/services/apiService';
import { useRouter } from 'vue-router';
import { useNotificationStore } from '@/store/notificationsStore';

export const Typeplateformes = {
  Lbac: 0,
  LbacTf: 1,
};

export const useUserStore = defineStore('user', () => {
  const router = useRouter();
  const registrationErrors = ref(null);
  const loginErrors = ref(null);
  const user = ref(null);
  const isAdmin = computed(() => {
    return user.value?.roles?.some(role => role === 'Admin_Lbac' || role === 'Admin_LbacTf');
  });
  const isLbac = computed(() => {
    return user.value?.roles?.some(role => role === 'Admin_Lbac' || role === 'Annonceur_Lbac' || role === 'Prestataire_Lbac');
  });
  const isLbacTf = computed(() => {
    return user.value?.roles?.some(role => role === 'Admin_LbacTf' || role === 'Annonceur_LbacTf' || role === 'Prestataire_LbacTf');
  });
  const isSuperAdmin = computed(() => {
    return user.value?.roles?.some(role => role === 'SuperAdmin');
  });
  const isAnnonceur = computed(() => {
    return user.value?.roles?.some(role => role === 'Annonceur_Lbac' || role === 'Annonceur_LbacTf');
  });
  const isPrestataire = computed(() => {
    return user.value?.roles?.some(role => role === 'Prestataire_Lbac' || role === 'Prestataire_LbacTf');
  });
  const isAdminLbac = computed(() => {
    return user.value?.roles?.some(role => role === 'Admin_Lbac');
  });
  const isAdminLbacTf = computed(() => {
    return user.value?.roles?.some(role => role === 'Admin_LbacTf');
  });

  const plateforme = ref(Typeplateformes.Lbac);
  const myAnnonce = ref(null);
  const myReponses = ref(null);
  const myArchives = ref(null);
  const userNotifications = ref([]);
  const filesCoftreFort = ref([]);
  const Notifications = ref([]);
  const countNotifications = ref(null);

  const register = async (formData) => {
    try {
      //clear cache
      registrationErrors.value = null;
      await apiService.register(formData);
      useNotificationStore().addNotification({
        type: 'success',
        message: 'Votre compte a été créé avec succès. Vous recevrez un email quand votre compte sera validé.',
      });
      router.push('/login');
    } catch (error) {
      if (error.response && error.response.data) {
        registrationErrors.value = error.response.data.errors;
        useNotificationStore().addNotification({
          type: 'error',
          message: error.response.data.message,
        });
      } else {
        registrationErrors.value = ['An unexpected error occurred.'];
        useNotificationStore().addNotification({
          type: 'error',
          message: 'An unexpected error occurred.',
        });
      }
    }
  };

  const login = async (formData) => {
    try {
      loginErrors.value = null;
      user.value = null;
      localStorage.removeItem('userToken');
      const response = await apiService.login(formData);
      localStorage.setItem('userToken', response.data.access_token);
      const roles = response.data.roles;
      if (roles.includes('Admin_Lbac') || roles.includes('Admin_LbacTf')) {
        router.push('/admin')
      } else {
        router.push('/annonces')
      }
    } catch (error) {
      //if it's 403 error show notification
      if (error.response && error.response.status === 403) {
        useNotificationStore().addNotification({
          type: 'error',
          message: error.response.data.message,
        });
      }
      loginErrors.value = error.response?.data?.message || 'Une erreur inattendue est survenue.';
    }
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    user.value = null;
    router.push('/login');
  };

  const isAuthenticated = async () => {
    try {
      if (user.value) return true;
      const response = await apiService.isAuthenticated();
      return response.data.authenticated;
    } catch (error) {
      return false;
    }
  };

  const getMyUserInfo = async () => {
    try {
      if (user.value) return user.value;
      const response = await apiService.getMyUserInfo();
      user.value = response.data;
      if(Notifications.value === null || Notifications.value.length === 0)
        fetchNotifications();
      return response.data;
    } catch (error) {
      return false;
    }
  }

  const getUserInfoById = async (userId) => {
    try {
      const response = await apiService.getUserInfoById(userId);
      return response.data;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error,
      });
      return null;
    }
  };

  const updateUser = async (user_id, formData) => {
    try {
      await apiService.updateUser(user_id, formData);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const uploadProfilePicture = async (formData) => {
    try {
      await apiService.uploadProfilePicture(formData);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  const changePlateforme = (newPlateformeKey) => {
    plateforme.value = newPlateformeKey;
    router.push('/annonces');
  };

  const verifyEmail = async (id, token) => {
    try {
      await apiService.verifyEmail(id, token);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getMyAnnonces = async () => {
    try {
      myAnnonce.value = null;
      const response = await apiService.getMyAnnonces();
      myAnnonce.value = response.data;
      return response.data;
    } catch (error) {
      return false;
    }
  }

  const getMyReponses = async (plateforme = 'all') => {
    try {
      myReponses.value = null;
      const response = await apiService.getUserReponsesAnnonces(plateforme);
      myReponses.value = response.data;
      return response.data;
    } catch (error) {
      return false;
    }
  }

  const refuseReponseAnnonce = async (id) => {
    try {
      await apiService.refuseReponseAnnonce(id);
      getMyReponses();
      getMyAnnonces();
      return true;
    } catch (error) {
      return false;
    }
  }

  const acceptReponseAnnonce = async (id) => {
    try {
      await apiService.acceptReponseAnnonce(id);
      getMyReponses();
      getMyAnnonces();
      return true;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
      return false;
    }
  }

  const getMyArchives = async () => {
    try {
      myArchives.value = null;
      const response = (await apiService.getMyArchive());
      myArchives.value = response.data;
      return response.data;
    } catch (error) {
      return false;
    }
  }

  const getUserNotifications = async (id) => {
    try {
      userNotifications.value = null;
      const response = await apiService.getUserNotifications(id);
      userNotifications.value = response.data;
      return response.data;
    } catch (error) {
      return false;
    }
  }

  const manageUserNotifications = async (id, payload) => {
    try {
      await apiService.manageUserNotifications(id, payload);
      useNotificationStore().addNotification({
        type: 'success',
        message: 'Notifications modifiées avec succès.',
      });
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
    }
  }

  const addFichierCoffreFort = async (formData, id) => {
    try {
      await apiService.addFichierCoffreFort(formData, id);
      useNotificationStore().addNotification({
        type: 'success',
        message: 'Fichier ajouté avec succès.',
      });
      getUserFiles(id);
      return true;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
      return false;
    }
  }

  const getUserFiles = async (id) => {
    try {
      filesCoftreFort.value = null;
      const response = await apiService.getUserFiles(id);
      filesCoftreFort.value = response.data.fichiers;
      return true;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
      return false;
    }
  }

  const deleteFileCoffreFort = async (id) => {
    try {
      await apiService.deleteFileCoffreFort(id);
      useNotificationStore().addNotification({
        type: 'success',
        message: 'Fichier supprimé avec succès.',
      });
      return true;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
      return false;
    }
  }

  const fetchNotifications = async () => {
    try {
      if (!user.value.user || !user.value.user.id)
        return;
      let id = user.value.user.id;
      Notifications.value = null;
      countNotifications.value = null;
      const response = await apiService.getNotifications(id);
      Notifications.value = response.data;
      countNotifications.value = response.data.length;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: error.response.data.message,
      });
    }
  }

  const SetPassword = async (id, token, payload) => {
    try {
      await apiService.setPassword(id, token, payload);
      useNotificationStore().addNotification({
        type: 'success',
        message: 'Mot de passe modifié avec succès.',
      });
      return true;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: 'Une erreur inattendue est survenue.' + error.response.data.message,
      });
    }
  }

  const sendPasswordResetLink = async (email) => {
    try {
      let payload = {
        email: email,
      };
      let rep = await apiService.sendForgotPassword(payload);
      useNotificationStore().addNotification({
        type: 'success',
        message: rep.data.message,
      });
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: 'Une erreur inattendue est survenue.' + error.response.data.message,
      });
    }
  }

  const ContactUs = async (email, subject, message, platform) => {
    try {
      let payload = {
        email: email,
        subject: subject,
        message: message,
        platform: platform
      };
      let rep = await apiService.contactUs(payload);
      useNotificationStore().addNotification({
        type: 'success',
        message: rep.data.message,
      });
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: 'Une erreur inattendue est survenue.' + error.response.data.message,
      });
    }
  }

  const ClearNotifications = async () => {
    try {
      let user_id = user.value.user.id;
      await apiService.clearNotifications(user_id);
      Notifications.value = [];
      countNotifications.value = 0;
    } catch (error) {
      useNotificationStore().addNotification({
        type: 'error',
        message: 'Une erreur inattendue est survenue.' + error.response.data.message,
      });
    }
  }

  return {
    registrationErrors,
    user,
    isAdmin,
    isSuperAdmin,
    isLbac,
    isLbacTf,
    isAnnonceur,
    isPrestataire,
    isAdminLbac,
    isAdminLbacTf,
    loginErrors,
    plateforme,
    myAnnonce,
    myReponses,
    myArchives,
    userNotifications,
    filesCoftreFort,
    Notifications,
    countNotifications,
    register,
    login,
    isAuthenticated,
    getMyUserInfo,
    getUserInfoById,
    updateUser,
    uploadProfilePicture,
    changePlateforme,
    logout,
    verifyEmail,
    getMyAnnonces,
    getMyReponses,
    refuseReponseAnnonce,
    acceptReponseAnnonce,
    getMyArchives,
    getUserNotifications,
    manageUserNotifications,
    addFichierCoffreFort,
    getUserFiles,
    deleteFileCoffreFort,
    fetchNotifications,
    SetPassword,
    sendPasswordResetLink,
    ContactUs,
    ClearNotifications,
  };
});

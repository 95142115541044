import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/userStore';

const MainPage = () => import('@/views/MainPage.vue');
const HomePage = () => import('@/views/HomePage.vue');
const LoginPage = () => import('@/views/LoginPage.vue');
const SignInPage = () => import('@/views/SignInPage.vue');
const AdminDashboardPage = () => import('@/views/AdminDashboardPage.vue');
const AddAnnonce = () => import('@/views/AddAnnoncePage.vue');
const Annonce = () => import('@/views/AnnoncePage.vue');
const ProfilPage = () => import('@/views/ProfilPage.vue');
const VerifyEmailPage = () => import('@/views/VerifyEmailPage.vue');
const AdminArchivePage = () => import('@/views/AdminArchivePage.vue');
const AdminAnnoncePage = () => import('@/views/AdminAnnoncePage.vue');
const AdminLstUserPage = () => import('@/views/AdminLstUserPage.vue');
const AdminUserPage = () => import('@/views/AdminUserPage.vue');
const AdminNewslettersPage = () => import('@/views/AdminNewsletterPage.vue');
const AdminCorbeillePage = () => import('@/views/AdminCorbeillePage.vue');
const PublicAnnoncePage = () => import('@/views/PublicAnnoncePage.vue');
const LegalMentionsPage = () => import('@/views/LegalMentionsPage.vue');
const AdminSuperAdminPage = () => import('@/views/AdminSuperAdminPage.vue');
const SetPasswordPage = () => import('@/views/SetPasswordPage.vue');
const ForgotPasswordPage = () => import('@/views/ForgotPasswordPage.vue');
const ResetPasswordPage = () => import('@/views/ResetPasswordPage.vue');
const AcceptRejectResponse = () => import('@/components/MailView/AcceptRejectResponse.vue');
const AdminEditorView = () => import('@/views/AdminEditorView.vue');
const AnnouncementAcceptedPage = () => import('@/views/AnnouncementAcceptedPage.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignInPage,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminDashboardPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/archive',
    name: 'Archive',
    component: AdminArchivePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/annonces',
    name: 'AdminAnnonce',
    component: AdminAnnoncePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/users',
    name: 'AdminLstUser',
    component: AdminLstUserPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/newsletters',
    name: 'AdminNewsletters',
    component: AdminNewslettersPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/user/:id',
    name: 'AdminUser',
    component: AdminUserPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/annonces',
    name: 'Main',
    component: MainPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/addAnnonce',
    name: 'AddAnnonce',
    component: AddAnnonce,
    meta: { requiresAuth: true },
  },
  {
    path: '/annonce/:id',
    name: 'Annonce',
    component: Annonce,
  },
  {
    path: '/profil',
    name: 'Profil',
    component: ProfilPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/verify-email/:id/:token',
    name: 'VerifyEmail',
    component: VerifyEmailPage,
  },
  {
    path: '/public',
    name: 'PublicAnnonce',
    component: PublicAnnoncePage,
  },
  {
    path: '/legalMentions',
    name: 'LegalMentions',
    component: LegalMentionsPage,
  },
  {
    path: '/superAdmin',
    name: 'SuperAdmin',
    component: AdminSuperAdminPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/set-password/:id/:token',
    name: 'SetPassword',
    component: SetPasswordPage,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
  },
  {
    path: '/reset-password/:id/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage,
  },
  {
    path: '/user/reponses-annonces/:id/:action',
    name: 'ManageResponse',
    component: AcceptRejectResponse,
    meta: { requiresAuth: true },
  }, 
  {
    path: '/admin/corbeille',
    name: 'Corbeille',
    component: AdminCorbeillePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/editor/:id',
    name: 'Editor',
    component: AdminEditorView,
    meta: { requiresAuth: true },
  },
  {
    path: '/annonce-accepted',
    name: 'AnnouncementAccepted',
    component: AnnouncementAcceptedPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    const isAuthenticated = await userStore.isAuthenticated();
    if (!isAuthenticated) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

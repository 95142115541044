import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref([]);

  const addNotification = (notification) => {
    const id = Date.now();
    notifications.value.push({ ...notification, id });
    setTimeout(() => removeNotification(id), 4000); // Supprime la notification après 3 secondes
  };

  const removeNotification = (id) => {
    notifications.value = notifications.value.filter(n => n.id !== id);
  };

  return {
    notifications,
    addNotification,
    removeNotification
  };
});

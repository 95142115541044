import App from './App.vue'
import { createApp } from 'vue'
import router from './router';
import { setupStore } from './store';
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'
import VCalendar from 'v-calendar';

import 'v-calendar/style.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css"
import "./assets/custom.scss";

const app = createApp(App);
app.use(router);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
setupStore(app);
app.use(Vue3Prism);
app.use(VCalendar, {})
app.component('QuillEditor', QuillEditor);
app.mount('#app');

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target)) && binding.value) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});
<template>
  <div id="app">
    <NotificationComponent />
    <router-view></router-view>
  </div>
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
export default {
  name: 'App',
  components: {
    NotificationComponent
  }
};
</script>

<style>
* {
  font-family: 'Archivo', sans-serif;
  box-sizing: revert !important;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  color: inherit;
  cursor: pointer;
  outline: none;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>

<template>
  <transition-group name="slide-notification" class="notification-container" tag="div">
    <div v-for="notification in notifications" :key="notification.id" :class="['notification', notification.type]">
      {{ notification.message }}
    </div>
  </transition-group>
</template>

<script setup>
import { useNotificationStore } from '@/store/notificationsStore';
import { storeToRefs } from 'pinia';
const { notifications } = storeToRefs(useNotificationStore());
</script>

<style scoped>
.notification-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification {
  padding: 20px 50px;
  margin-bottom: 10px;
  color: white;
  border-radius: 5px;
  width: auto;
  max-width: calc(100vw - 40px);
}

.notification.success {
  background-color: #06b48a;
}

.notification.error {
  background-color: #EF2538;
}

.slide-notification-enter-active,
.slide-notification-leave-active {
  transition: all 0.5s ease;
}

.slide-notification-enter-from,
.slide-notification-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.slide-notification-enter-to,
.slide-notification-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
